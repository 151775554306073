import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import * as inlineStyles from "../components/Layout/inlineStyles"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container" style={inlineStyles.container}>
      <h1>Sidan kunde inte hittas</h1>
      <p>På den här adressen verkar det inte finnas någon sida.</p>
      <p>
        Det kan bero på att du klickat på en länk som inte fungerar eller att
        sidan du försökt nå är borttagen.
      </p>
      <p>
        <a href="mailto:opal@opal.se">
          Kontakta oss gärna om du inte hittar det du söker.
        </a>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
